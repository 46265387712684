'use client';

import { Layout } from 'antd';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import React from 'react';

import Link from '@/components/core/Link';

import { useTranslation } from '@/app/i18n/client';

const { Footer } = Layout;

const footerStyle: React.CSSProperties = {
  padding: 0,
};

const DesktopFooter: React.FC = () => {
  const { lng } = useParams();
  const { t } = useTranslation(lng as string, 'HomePage');

  return (
    <Footer style={footerStyle}>
      <div className='hidden h-11 w-full bg-[#000000] px-6'>
        <div className='text-14 inter-normal container-pc flex h-full flex-row items-center justify-between'>
          <div className='flex flex-row items-center gap-[70px] text-white'>
            <img className='h-6 shrink-0' src='/img/footer/ezugi.png' alt='' />

            <img
              className='h-6 shrink-0'
              src='/img/footer/techplay.png'
              alt=''
            />

            <img className='h-6 shrink-0' src='/img/footer/ebet.png' alt='' />

            <img
              className='h-6 shrink-0'
              src='/img/footer/evolution.png'
              alt=''
            />

            <img
              className='h-6 shrink-0'
              src='/img/footer/n2-live.png'
              alt=''
            />

            <img
              className='h-6 shrink-0'
              src='/img/footer/pracmatic.png'
              alt=''
            />

            <img className='h-6 shrink-0' src='/img/footer/playgo.png' alt='' />

            <img
              className='h-6 shrink-0'
              src='/img/footer/microgaming.png'
              alt=''
            />

            <img
              className='h-6 shrink-0'
              src='/img/footer/vivogaming.png'
              alt=''
            />
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid var(--Linear, #FFF3CB)',
          background:
            'radial-gradient(143.73% 114.62% at 50.16% -7.95%, #D3A300 0%, #796843 0.01%, #2D2925 100%, #2D2925 100%)',
        }}
        className='relative flex h-[200px] flex-row items-center justify-between px-2'
      >
        <img
          className='pointer-events-none absolute bottom-0 left-0 h-[171px] w-[189px]'
          src='/img/footer/img_3.png'
          alt=''
        />
        <img
          className='pointer-events-none absolute bottom-0 right-0 h-[171px] w-[189px]'
          src='/img/footer/img_4.png'
          alt=''
        />
        <div className='container-pc flex flex-row items-center justify-between'>
          <Image
            priority
            width={230}
            height={56}
            className='h-[56px] w-[230px] object-contain'
            src='/img/logo/main-logo-white.svg'
            alt=''
          />
          <div className='text-14 inter-normal grid grid-cols-5 gap-x-14 gap-y-3 text-[#EAEAEA]'>
            <span className='mb-4'>
              <b>{t('footer.games')}</b>
            </span>
            <span className='mb-4'></span>
            <span className='mb-4'></span>
            <span className='mb-4'>
              <b>{t('footer.info')}</b>
            </span>
            <span className='mb-4'>
              <b>{t('footer.support')}</b>
            </span>
            {/* <span>
              <Link className='!text-white' href='/game/hot'>
                {t('header.menuItem13')}
              </Link>
            </span> */}

            <span>
              <Link className='!text-white' href='/game/live-casino'>
                {t('header.menuItem1')}
              </Link>
            </span>

            <span>
              <Link className='!text-white' href='/game/sports'>
                {t('header.menuItem4')}
              </Link>
            </span>

            <span>
              <Link className='!text-white' href='/game/tables'>
                {t('header.menuItem11')}
              </Link>
            </span>

            <span>
              <Link
                className='!text-white'
                href='/information/terms-and-conditions'
              >
                {t('footer.menuItem3')}
              </Link>
            </span>
            <span>
              <a
                className='!text-white'
                target='_blank'
                rel='noopener nofollow'
                href='https://line.me/R/ti/p/@leo88support?from=page&amp;searchId=leo88support'
              >
                {t('footer.menuItem6')}
              </a>
            </span>

            <span>
              <Link className='!text-white' href='/game/fishing'>
                {t('header.menuItem7')}
              </Link>
            </span>
            <span>
              <Link className='!text-white' href='/game/esport'>
                {t('header.menuItem6')}
              </Link>
            </span>
            <span>
              <Link className='!text-white' href='/game/lottery'>
                {t('header.menuItem12')}
              </Link>
            </span>
            <span>
              <Link className='!text-white' href='/promotion'>
                {t('footer.menuItem4')}
              </Link>
            </span>
            <span>
              <a
                className='!text-white'
                target='_blank'
                rel='noopener nofollow'
                href='https://direct.lc.chat/16386009/'
              >
                {t('footer.menuItem7')}
              </a>
            </span>

            <span>
              <Link className='!text-white' href='/game/cock-fight'>
                {t('header.menuItem15')}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default DesktopFooter;
